#root {
    min-height: 100vh;
    max-width: 100%;
    background-color: #eeeeee;
    .pageWrapper {
        padding: 30px 32px;
        flex: 1 0;
        position: relative;
        max-width: calc(100vw - 256px - 64px);
        .title {
            font-size: 36px;
            font-weight: bold;
            color: #222222;
        }
        .pageContent {
            background-color: #eeeeee;
        }
    }

    .pageWrapperforDestop {
        padding: 30px 32px;
        flex: 1 0;
        position: relative;
        max-width: calc(100vw - 256px - 64px);
        .title {
            font-size: 36px;
            font-weight: bold;
            color: #222222;
            @media (max-width: 1208px) {
                font-size: 22px;
                font-weight: bold;
                margin: 8px 0 14px 10px;
                text-align: left;
                color: #222222;
            }
        }
        .pageContent {
            background-color: #eeeeee;
        }
        @media (max-width: 1208px) {
            width: 100%;
            background-color: "#eeeeee";
        }
    }
}

#superfollowCmsContainer {
    display: flex;
    .mainWrapper {
        margin-top: 88px;
        display: flex;
        width: 100%;
    }
}
